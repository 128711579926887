<template>
  <div id="app" class="container mt-5">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <!-- <router-link to="/test">{{this.$route}}</router-link> | -->
      <router-link to="/todo">Todo</router-link> |
      <router-link to="/mongo">Mongo</router-link>
    </nav>
    
    <router-view/>
    
  </div>
</template>

<script>
export default {
  name: 'App',
}
console.log('route: ');
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

nav {
  padding: 30px;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}

#app .shortcuts-container {
  flex-direction: column;
}
</style>